// Generic imports
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

// Other components imports
import Layout from "components/layout"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports

const NotFoundPage = () => {
  const [isNotFound, setNotFound] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setNotFound(true)
    }, 2000)
  }, [])

  return (
    <Layout>
      {isNotFound && (
        <>
          <SEO title="404: Not found" />
          <div>
            <h1 style={{ paddingTop: "20vh", textAlign: "center" }}>
              NOT FOUND
            </h1>
            <Link
              to="/"
              style={{
                paddingBottom: "20vh",
                display: "block",
                textAlign: "center",
              }}
            >
              Back to home
            </Link>
          </div>
        </>
      )}
    </Layout>
  )
}

export default NotFoundPage
